/* bootstrap overwrite */
.quote-component {
  background-color: #e4eaf7 !important;
  color: var(--text-color) !important;
}
.quote-component .bordure {
  background-image: url("../../images/bordure.svg") !important;
  background-position: center !important;
}
.quote-component .pronunciation {
  color: var(--text-color) !important;
}