@import 'webresources-sources/scss/imports';

.quote-component {
  background-color: $color-bluegray-400 !important;
  color: var(--text-color) !important;

  .bordure {
    background-image: url('../../images/bordure.svg') !important;
    background-position: center !important;
  }

  .pronunciation {
    color: var(--text-color) !important;
  }
}
